
.img-container img {
    width:100%;
    object-fit:cover;
}


.img-container {
  margin-left: auto;
  margin-right: auto;

    
}

.mobileImages {
  margin-bottom:12px;
}

.gallery-item{
    display:none;
}

.text-container {
    margin-bottom:5%;
}


@media (min-width: 1025px) {

li {
    text-align: unset;
}

.mobileImages {
    display:none;
}

.img-container {
    
  flex-direction: row;
  width:47%;
  
  /*
  grid-template-columns: 1fr 1fr 1fr;
  margin-left:10%;
  margin-right:10%;
  grid-gap:2rem;
  */
  
}

.gallery-item {
    display:unset;
    width:100%;
    height:100%;
    position:relative;
}

.text-container {
  margin: 0 26.39% 2.5% 26.39%;
}

.text-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap:0 1.75em;
  }
  
  .text-column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1
  }


.single-column{
  display: flex;
  flex-direction: column;
  width:50%;
  
}

}

.hideClass {
    display:none;
}