.container {
    padding: 0px 12px 24px 12px;
    margin-top:13.5vh;
    
    
}

.img-container {
  

}
img {
    /*align-self: center;*/
    width:100%;
      
}

@media (min-width: 1025px) {
    .container {
        width:80%;
        margin:auto;
        margin-top:22.5vh;
    }

    .img-container {
        /*
      flex-direction: row;
      width:50%;
      */
      margin:2rem 25% 2rem;
      width:20%;
      
      
    }
    .text-wrapper{
    padding-top: 0.4em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 1.75em;
    justify-content: center;
   
    }
    .text-wrapper-top {
        margin-left:25%;
        width:30%;
        
    }
    .col-1 {
        width:420px;
    }
    .col-2 {
        width:420px;
        margin-top:42.56px;
    } 
  }

.sub_headings {
    font-size:18px;
}