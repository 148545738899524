
.img-container img {
    
    width:100%;
    object-fit:cover;
    
    
}


.img-container {
    /*
    display:flex;
    flex-direction: column;
    object-fit: contain;
    gap: 0.4rem;
    */
    display: grid;
    grid-gap:0.5rem;
    justify-content: center;
    align-items: center;
    
    
}

.gallery-item{
    width:100%;
    height:100%;
    position:relative;
}

.box{
    border:2px solid black;
    height:100%;
}

@media (min-width: 1024px) {
.img-container {
    /*
  flex-direction: row;
  width:50%;
  */
  grid-template-columns: 1fr 1fr 1fr;
  margin-left:10%;
  margin-right:10%;
  grid-gap:2rem;
}
}