.collumns{
    /*display:grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-gap: 1rem;
    */

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        padding: 10px;
 
    
}

.col-1 {
    
}