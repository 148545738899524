.container {
    padding: 0px 18px 24px 18px;
    margin-top:25%;
    
    
}


@media (min-width: 1025px) {
    .container {
        
        margin-top:16%;
        padding:0;
    }
}

@media (min-width: 1439px) {
    .container {
        
        margin-top:13%;
        
    }
}