.close {
  display:hidden;
}

@media (min-width: 1024px) {

.close {
    position: absolute;
    right: 40px;
    top: 32px;
    width: 32px;
    height: 22px;
    opacity: 0.3;
    background-color: transparent;
    border:none;
    z-index:9999;
    transition: ease-in 0.1s;
  }
  .close:hover {
    opacity: 0.5;
   
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 22px;
    width: 2px;
    background-color: #808080;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }

  .hide {
      display:none;
  }
}