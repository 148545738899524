

body {
  font-family: futuran; 
  margin:0;
  padding:0;

}

.page-container {
  display:flex;
  flex-direction:column;
  min-height:100vh;
}

.content-wrap {
  flex:1;
}
header {
  margin-top:15vh;

  /*
  background-image: url(./assets/img/1.jpg);
  background-size:cover;
  background-repeat: no-repeat;
  background-position: top right;
  height: 100vh;  
  font-size: 1rem;
  */
  /*background-color: rgba(0,0,0, 0.2);
  animation:fadein 2.5s;
  
  background-blend-mode: darken;
  */
}
/*
@keyframes fadein {
  from { opacity: 0.85; }
  to   { opacity: 1; }
}
*/
h1, h2 {
  color: #808080;
  font-weight: normal;
  font-size: 	1.125rem
}

li {
  color: #808080;
}

@media (min-width: 1025px) {
  header{
    margin-top:22.5vh;
  }
}

