.overlay {
    
    min-height:100vh;
    background: white;
    position:fixed;
    overflow:hidden;
    top:0;
    left:0;
    width:100%; 
    padding:0;
    z-index:1;
    
}