.header-background {
    background-image: url(../../assets/img/1.jpg);
    background-size:cover;
    background-repeat: no-repeat;
    background-position: top right;
    height: 100vh;  
    
    
    
    /*background-color: rgba(0,0,0, 0.2);
    animation:fadein 2.5s;
    
    background-blend-mode: darken;*/
    font-size: 1rem;
  
   
    
    
   
  }