
.toggle-button {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height: 10px;
    width:25px;
    background: transparent;
    border:none;
    cursor: pointer;
    padding:0;
    box-sizing: border-box;
    z-index:9998;
    outline:none;
    -webkit-tap-highlight-color: transparent; 
    margin-top:0.3rem;
}

.toggle-button__line{
    width:23px;
    height:1px;
    background: #808080;
    z-index:9999;
}

.toggle-button__line_open{
    width:23px;
    height:1px;
    background: #808080;
    z-index:9999;
    transition: all .2s ease-out;
    transform: rotate(135deg);
    position:fixed;
    top:1;
    margin-top:5px;
}

.top_open {
    position:fixed;
    transform: rotate(45deg);
    
}

@media (min-width:1024px){
    .toggle-button{
    /*display:none;*/
    }
}