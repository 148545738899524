.right-arrow, .left-arrow {
    display:none;
    
}

.slider{
    display:none;
}
@media (min-width: 1024px) {

.slider {
    display:unset;
    background-color: white;
    position: absolute;
    top: 0;
    left: right;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    display:flex;
    justify-content: center;
    align-items: center;

}


.SlideImage {
    
    max-height:80vh;
    width:auto;
    /*
    width:auto;
    max-height:50vh;
    */

}

.right-arrow {
    display:unset;
    position: absolute;
    top: 50%;
    right: 40px;
    font-size: 2rem;
    color: gray;
    opacity: 0.2;
    cursor: pointer;
    user-select: none;
}

.left-arrow {
    display:unset;
    position: absolute;
    top: 50%;
    left: 40px;
    font-size: 2rem;
    opacity: 0.2;
    color: gray;
    cursor: pointer;
    user-select: none;
}
}