  
  

  .toolbar-navigation-items {
    justify-content: flex-end;
  }
  
  .toolbar-navigation-items ul {
    list-style-type:none;
    margin:0;
    display:flex;
  }
  
  .toolbar-navigation-items li {
    padding: 0 0 0 3.2rem;
     
  }
  
  .toolbar-navigation-items a{
    text-decoration: none;
    color: #808080; 
    border-bottom: 1px solid rgba(197, 197, 197, 0);
    transition: border 500ms ease;
    padding-bottom: 3px;
    

  }
  
  .toolbar-navigation-items a:hover,
  .toolbar-navigation-items a:active {
    border-bottom: 1px solid #808080;
  }
  
  
  .toolbar_navigation {
    position:absolute;
    top:0;
    right:0;
    width:80%;
    display:flex;
    justify-content: space-between;
    /*align-items:center;*/
    padding:0 10% 0 10%; 
    padding-top:1.875em;
    list-style-type:none;
    
    
  }
  
   
.brand a{
  text-decoration:none;
  color: #808080;
  letter-spacing: 0.04em;
  -webkit-tap-highlight-color: transparent;
  font-size:0.85em;

}

.logotype {
  height:40px;
  
}

.break-mobile {
  display:inline-block;
  margin-right:20px;
}

@media (max-width: 767px){

  .brand {
    width:250px;
  }
}


@media (min-width: 1025px) {
      .toolbar_navigation {
        padding:13.5vh 10% 0 13%;
       
        
      }
  
    }
@media (max-width: 1023px) {
      .toolbar-navigation-items {
        display:none;
      }
    }
  